export const Fields = [
  [
    {
      object: "InputWrapper",
      label: "Work email",
      id: "email",
      inputType: "email",
      inputPlaceholder: "Enter Email Address"
    }
  ],
  [
    {
      object: "GridInputWrapper",
      label: "First name",
      id: "fname",
      inputType: "text",
      inputPlaceholder: "Enter First Name"
    },
    {
      object: "GridInputWrapper",
      label: "Last name",
      id: "lname",
      inputType: "text",
      inputPlaceholder: "Enter Last Name"
    },
    {
      object: "InputWrapper",
      label: "Company Website",
      id: "cweb",
      inputType: "text",
      inputPlaceholder: "Enter Your Website"
    }
  ],
  [
    {
      object: "InputWrapper",
      label: "Company name",
      id: "cname",
      inputType: "text",
      inputPlaceholder: "Enter Company Name"
    },

    {
      object: "subdomain",
      label: "Your Pavelify Subdomain",
      id: "subdomain",
      inputType: "slug",
      inputPlaceholder: "Enter Subdomain Name"
    },

    {
      object: "InputWrapper",
      label: "Create a password",
      id: "password",
      inputType: "password",
      inputPlaceholder: "Enter Password"
    },

    {
      object: "button",
      text: "Complete"
    },
    {
      object: "text",
      text: `By clicking "Complete" you acknowledge and accept `,
      linkterm: "Pavelify Terms",
      linkprivacy: "Privacy Notice"
    }
  ]
];
